import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import { Flex } from 'components/Grid'
import styled, { theme, themeGet } from 'util/style'
import {
  VULNERABILITY_COLORS,
  VULNERABILITY,
  VULNERABILITY_LEVELS,
} from '../../../config/constants'


const Header = styled.div`
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin-bottom: 1em;

  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: ${({ level }) => (level > 3 ? '#FFF' : themeGet('colors.black'))};
  background-color: ${({ level }) => VULNERABILITY_COLORS[level]};
`

const Labels = styled(Flex)`
  margin-top: 0.25em;
  font-size: 0.7em;
  color: ${themeGet('colors.grey.600')};
`

const ItemContainer = styled(Flex)`
  margin: 0 0.5em;
`

const borderColor = theme.colors.primary[800]

const borderRadius = (isLower, isUpper) => {
  if (!(isLower || isUpper)) return '0'

  if (isLower && isUpper) {
    return '0.5em'
  }
  return isLower ? '0.5em 0 0 0.5em' : '0 0.5em 0.5em 0'
}

const Item = styled.div`
    flex: 1 0 auto;
    height: ${({ isActive }) => (isActive ? '2rem' : '1.5em')};
    background-color: ${({ color }) => color};
    border-radius: ${({ isLower, isUpper }) => borderRadius(isLower, isUpper)};
    z-index: ${({ isLower, isUpper }) => (isLower || isUpper ? 10 : 0)};

    ${({ isLower }) =>
      isLower &&
      css`
        border-left: 4px solid ${borderColor}};
        margin-left: -4px;
      `}

    ${({ isUpper }) =>
      isUpper &&
      css`
        border-right: 4px solid ${borderColor}};
        margin-right: -4px;
        position: relative;

        &:after,
        &:before {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: transparent;
          border-bottom-color: ${borderColor};
          border-width: 16px;
          margin-left: -16px;
        }
        &:before {
          border-color: transparent;
          border-bottom-color: ${borderColor};
          border-width: 16px;
          margin-left: -16px;
        }
      `}

    ${({ isActive }) =>
      isActive &&
      css`
        border-top: 4px solid ${borderColor};
        border-bottom: 4px solid ${borderColor};
      `}
`

const Vulnerability = ({ vulnerability, levels }) => {
  let [lower, upper] = vulnerability
  upper = upper || lower

  const upperLabel = upper !== lower ? ` to ${VULNERABILITY[upper]}` : ''
  const label = `${VULNERABILITY[lower]}${upperLabel}`

  return (
    <div>
      <Header level={upper || lower}>{label}</Header>
      <ItemContainer alignItems="center">
        {levels.map(level => (
          <Item
            key={level}
            color={VULNERABILITY_COLORS[level]}
            isActive={level >= lower && level <= upper}
            isLower={level === lower}
            isUpper={level === upper}
          />
        ))}
      </ItemContainer>
      <Labels justifyContent="space-between" alignItems="center">
        <div>lower vulnerability</div>
        <div>higher vulnerability</div>
      </Labels>
    </div>
  )
}

Vulnerability.propTypes = {
  vulnerability: PropTypes.arrayOf(PropTypes.number).isRequired,
  levels: PropTypes.arrayOf(PropTypes.number).isRequired
}

export default Vulnerability
